/*
 * Component: Form
 * ---------------
 */
.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #d2d6de;
}
.form-control:focus {
  border-color: #3c8dbc;
  box-shadow: none;
}
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #bbb;
  opacity: 1;
}
.form-control:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-group.has-success label {
  color: #00a65a;
}
.form-group.has-success .form-control {
  border-color: #00a65a;
  box-shadow: none;
}
.form-group.has-success .help-block {
  color: #00a65a;
}
.form-group.has-warning label {
  color: #f39c12;
}
.form-group.has-warning .form-control {
  border-color: #f39c12;
  box-shadow: none;
}
.form-group.has-warning .help-block {
  color: #f39c12;
}
.form-group.has-error label {
  color: #dd4b39;
}
.form-group.has-error .form-control {
  border-color: #dd4b39;
  box-shadow: none;
}
.form-group.has-error .help-block {
  color: #dd4b39;
}
/* Input group */
.input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff;
}
/* button groups */
.btn-group-vertical .btn.btn-flat:first-of-type,
.btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0;
}
.icheck > label {
  padding-left: 0;
}
/* support Font Awesome icons in form-control */
.form-control-feedback.fa {
  line-height: 34px;
}
.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: 46px;
}
.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: 30px;
}
/*
 * Component: Button
 * -----------------
 */
.btn {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
}
.btn.uppercase {
  text-transform: uppercase;
}
.btn.btn-flat {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-width: 1px;
}
.btn:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:focus {
  outline: none;
}
.btn.btn-file {
  position: relative;
  overflow: hidden;
}
.btn.btn-file > input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  opacity: 0;
  filter: alpha(opacity=0);
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
}
.btn-default:hover,
.btn-default:active,
.btn-default.hover {
  background-color: #e7e7e7;
}
.btn-primary {
  background-color: #3c8dbc;
  border-color: #367fa9;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.hover {
  background-color: #367fa9;
}
.btn-success {
  background-color: #00a65a;
  border-color: #008d4c;
}
.btn-success:hover,
.btn-success:active,
.btn-success.hover {
  background-color: #008d4c;
}
.btn-info {
  background-color: #00c0ef;
  border-color: #00acd6;
}
.btn-info:hover,
.btn-info:active,
.btn-info.hover {
  background-color: #00acd6;
}
.btn-danger {
  background-color: #dd4b39;
  border-color: #d73925;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.hover {
  background-color: #d73925;
}
.btn-warning {
  background-color: #f39c12;
  border-color: #e08e0b;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.hover {
  background-color: #e08e0b;
}
.btn-outline {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn[class*='bg-']:hover {
  -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
}
.btn-app {
  border-radius: 3px;
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
}
.btn-app > .fa,
.btn-app > .glyphicon,
.btn-app > .ion {
  font-size: 20px;
  display: block;
}
.btn-app:hover {
  background: #f4f4f4;
  color: #444;
  border-color: #aaa;
}
.btn-app:active,
.btn-app:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-app > .badge {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 10px;
  font-weight: 400;
}
body {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}
img {
  max-width: 100%;
}
body {
  background-color: #222d32;
}
#login-container .logo {
  padding: 100px 0 20px 0;
  font-size: 3em;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
#login-container > form {
  max-width: 350px;
  margin: 0 auto;
  padding: 30px;
  background-color: #00a65a;
  color: #fff;
}
