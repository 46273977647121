@import (reference) "bootstrap-less/mixins.less";
@import (reference) "bootstrap-less/variables.less";

@import "adminlte/mixins";
@import "adminlte/variables";
@import "adminlte/forms";
@import "adminlte/buttons";

@import "variables";
@import "base";

body {
  background-color: @sidebar-dark-bg;
}

#login-container {

  .logo {
    padding: 100px 0 20px 0;
    font-size: 3em;
    text-align: center;
    color: #fff;
    font-weight: 300;
  }

  > form {
    max-width: 350px;
    margin: 0 auto;
    padding: 30px;
    background-color: @green;
    color: #fff;
  }
}